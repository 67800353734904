<template>
    <div class="container col-12 col-lg-7 col-xl-6 mx-auto">
        <ul v-if="comments && comments.length > 0" class="list-group">
            <template v-for="(comment, key) in comments">
                <Comment :key="key" :comment="comment"></Comment>
            </template>
            <br />
            <p
                v-if="hasMorePages"
                @click="showMore"
                class="cursor fw-bold text-center"
            >
                Show More
            </p>
        </ul>
        <div v-else>No Comments to show yet.</div>
    </div>
</template>

<script>
import gql from 'graphql-tag';
import blogCommentsQuery from '@/graphql/queries/comments/CommentsByBlogId.gql';
import productCommentsQuery from '@/graphql/queries/comments/CommentsByProductId.gql';
// import Button from '../../components/reusable/Button.vue';
import { mapActions, mapGetters } from 'vuex';
import Comment from '@/views/comment/Details';

export default {
    components: {
        Comment,
        // 'main-button': Button,
    },
    data() {
        return {
            pageNo: 1,
            hasMorePages: false,
        };
    },
    mounted() {
        const pathname = window.location.pathname;
        if (pathname.startsWith('/blogs')) {
            this.fetchCommentsById();
        } else {
            this.fetchCommentsByProductId();
        }
    },
    methods: {
        ...mapActions('blog', ['showBuyerguide', 'hideBuyerguide']),

        fetchCommentsById() {
            this.$apollo
                .query({
                    query: gql`
                        ${blogCommentsQuery}
                    `,
                    variables: {
                        pageNo: this.pageNo,
                        blogSlug: this.$route.params.slug,
                    },
                })
                .then(data => {
                    const {
                        paginatorInfo,
                        data: comments,
                    } = data.data.commentsByBlogId;
                    this.hasMorePages = paginatorInfo.hasMorePages;
                    this.$store.commit('comment/ADD_COMMENTS', {
                        comments: comments,
                    });
                    // console.log(comments);
                });
        },

        fetchCommentsByProductId() {
            this.$apollo
                .query({
                    query: gql`
                        ${productCommentsQuery}
                    `,
                    variables: {
                        pageNo: this.pageNo,
                        productSlug: this.$route.params.slug,
                    },
                })
                .then(data => {
                    const {
                        paginatorInfo,
                        data: comments,
                    } = data.data.commentsByProductId;
                    this.hasMorePages = paginatorInfo.hasMorePages;
                    this.$store.commit('comment/ADD_COMMENTS', {
                        comments: comments,
                    });
                });
        },

        showMore() {
            this.pageNo++;

            this.$apollo
                .query({
                    query: this.commentsquery,

                    variables: {
                        pageNo: this.pageNo,
                        blogSlug: this.$route.params.slug,
                    },
                })
                .then(response => {
                    const pathname = window.location.pathname;
                    if (pathname.startsWith('/blogs')) {
                        this.hasMorePages =
                            response.data.commentsByBlogId.paginatorInfo.hasMorePages;

                        const comments = response.data.commentsByBlogId.data;

                        comments.map(comment => {
                            this.$store.commit(
                                'comment/APPEND_COMMENT',
                                comment,
                            );
                        });
                    } else {
                        this.hasMorePages =
                            response.data.commentsByProductId.paginatorInfo.hasMorePages;

                        const comments = response.data.commentsByProductId.data;

                        comments.map(comment => {
                            this.$store.commit(
                                'comment/APPEND_COMMENT',
                                comment,
                            );
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    computed: {
        ...mapGetters({
            comments: 'comment/getComments',
        }),
        commentsquery() {
            const pathname = window.location.pathname;
            if (pathname.startsWith('/blogs')) {
                return blogCommentsQuery;
            } else {
                return productCommentsQuery;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.comments {
    max-width: 768px;
    margin: 0 auto;
}
</style>
