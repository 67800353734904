<template>
    <div v-if="isLoggedIn" class="col-12 col-lg-7 col-xl-6 mx-auto container">
        <form @keyup.enter="postComment">
            <p class="fw-bold">{{ user }}</p>
            <div class="form-group d-flex align-items-center gap-2 mx-auto">
                <textarea
                    name="comment"
                    rows="3"
                    class="form-control bg-light"
                    v-model="comment"
                    placeholder="Comment"
                    style="border-radius:11px"
                ></textarea>
                <font-awesome-icon
                    icon="paper-plane"
                    :class="{ 'is-loading': submit }"
                    :disabled="!isValid"
                    @click.prevent="postComment"
                    type="submit"
                    class="icon"
                />
            </div>
        </form>
    </div>
    <div v-else class="comment-box w-50 mx-auto">
        <div
            class="alert alert-primary cursor text-center"
            role="alert"
            @click="toAddComment"
        >
            Please login to add comments
        </div>
    </div>
</template>

<script>
import AddBlogCommentMutation from '../../graphql/mutations/comment/AddCommentToBlog.gql';
import AddProductCommentMutation from '../../graphql/mutations/comment/AddCommentToProduct.gql';
import { mapGetters } from 'vuex';

export default {
    name: 'Create',
    data() {
        return {
            submit: false,
            comment: '',
        };
    },
    methods: {
        blogComment() {
            this.submit = true;
            this.$apollo
                .mutate({
                    mutation: AddBlogCommentMutation,
                    variables: {
                        blogSlug: this.$route.params.slug,
                        comment: this.comment,
                    },
                })
                .then(response => {
                    this.comment = '';
                    // console.log(response.data.addCommentToBlog);
                    this.$store.commit(
                        'comment/APPEND_COMMENT',
                        response.data.addCommentToBlog,
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },
        productComment() {
            this.submit = true;
            this.$apollo
                .mutate({
                    mutation: AddProductCommentMutation,
                    variables: {
                        productSlug: this.$route.params.slug,
                        comment: this.comment,
                    },
                })
                .then(response => {
                    this.comment = '';
                    // console.log(response.data);
                    this.$store.commit(
                        'comment/APPEND_COMMENT',
                        response.data.addCommentToProduct,
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },
        postComment() {
            const pathname = window.location.pathname;
            if (pathname.startsWith('/blogs')) {
                this.blogComment();
            } else {
                this.productComment();
            }
        },
        toAddComment() {
            this.$router.push({
                name: 'Login',
            });
        },
    },
    computed: {
        isValid() {
            return this.comment !== '';
        },
        ...mapGetters({
            isLoggedIn: 'auth/isLoggedIn',
            user: 'auth/getMyName',
        }),
    },
};
</script>

<style scoped>
.icon {
    background: #3cb207;
    color: #fff;
    padding: 1rem;
    width: 5.188rem;
    height: 3.5rem;
    border-radius: 36px;
}
</style>
