<template>
    <li class="list-unstyled py-3 border-bottom">
        <small class="m-0">{{ comment.user.name }}</small>
        <p class="m-0 fw-bold">{{ comment.body }}</p>
        <!-- <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{ comment.body }}</h5>
            <small style="color:grey">{{ comment.created_at }}</small>
        </div> -->
    </li>
</template>

<script>
export default {
    name: 'Comment',
    props: ['comment'],
    mounted() {},
};
</script>

<style lang="scss" scoped></style>
